import { css, MenuItem, Select } from '@mui/material';
import { find } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import WatchSymbolToggleForStocknerve from './WatchSymbolToggleForStocknerve';
import { useSnapshot } from 'valtio';
import { groupStore } from '../daddy960_stocknerve_SidePane2';
export const WatchSymbolToggleSelectorForStocknerve = memo(function WatchSymbolToggleSelectorForStocknerve(props) {
    const state = useSnapshot(groupStore);
    const watchListGroup = state.addWatchListGroup;
    const groupName = state.selectedGroupName;
    const [name, setName] = useState('群組1');
    useEffect(() => {
        setName('');
        //做這件事是強制 NumberInput re-render中間的數量 單位才會更新
        const timeoutId = setTimeout(() => {
            setName(groupName);
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [groupName]);
    return (<div css={css `
        ${fill_horizontal_all_center}
        gap:4px;
      `}>
      <div css={css `
          ${fill_horizontal_all_center}
          width: 50%;
        `}>
        自選股
      </div>
      <Select fullWidth css={css `
          height: 32px;
        `} size='small' value={name} onChange={event => {
            const eventValue = event.target.value;
            groupStore.selectedGroupName = eventValue;
            const selectedWatchListGroup = find(props.config, item => {
                return item.displayText === eventValue;
            });
            if (selectedWatchListGroup) {
                groupStore.addWatchListGroup =
                    selectedWatchListGroup?.groupName;
            }
            else {
                console.error(`找不到watchList群組`);
            }
        }}>
        {props.config?.map((value, index) => {
            return (<MenuItem key={index} value={value.displayText}>
              {value.displayText}
            </MenuItem>);
        })}
      </Select>
      <WatchSymbolToggleForStocknerve.display groupName={watchListGroup} maxSize={20}/>
    </div>);
});
