import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { SortArrow } from '~/modules/new-version-counsultant/Quote/SortArrow';
import { stockDecimal } from '~/modules/new-version-counsultant/Quote/stockDecimal';
import { useSortSignalrDataStore, useSortSignalrDataStore2, } from '~/modules/symbolQuote/simple/useSortStore';
import { store } from '~/pages/heineken_template/_private/store';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { stocknerve_store } from '../daddy960_stocknerve_SidePane3';
import { css } from '@emotion/react';
const autoDecimal = (quoteValue) => {
    return quoteValue.toFixed(stockDecimal(quoteValue));
};
const StockIndexFuturesSymbolBody = memo(function StockIndexFuturesSymbolBody(props) {
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(stocknerve_store);
    const displayType = state.displayType;
    const indexValue = props.indexSymbol;
    const indexSymbol = indexValue?.symbol ?? '';
    const indexClose = indexValue?.close ?? 0;
    const indexVolume = indexValue?.volume ?? 0;
    const indexChanges = processQuoteToReadable(indexValue);
    const indexChangeSymbol = indexChanges?.closeChange > 0 ? '+' : '';
    const iLimitUp = indexValue?.limitUpPrice;
    const iLimitDn = indexValue?.limitDownPrice;
    const iLimit = indexClose === iLimitUp || indexClose === iLimitDn;
    const futuresValue = props.futuresSymbol;
    const futuresSymbol = futuresValue?.symbol ?? '';
    const futuresClose = futuresValue?.close ?? 0;
    const futuresVolume = futuresValue?.volume ?? 0;
    const futuresChanges = processQuoteToReadable(futuresValue);
    const futuresChangeSymbol = futuresChanges?.closeChange > 0 ? '+' : '';
    const fLimitUp = futuresValue?.limitUpPrice;
    const fLimitDn = futuresValue?.limitDownPrice;
    const fLimit = futuresClose === fLimitUp || futuresClose === fLimitDn;
    const selected = charting.symbol === indexSymbol || charting.symbol === futuresSymbol;
    const indexSelected = charting.symbol === indexSymbol;
    const futuresSelected = charting.symbol === futuresSymbol;
    const volumeTo8e = (value) => {
        if (value > 1e8) {
            return (value / 1e8).toFixed(0) + '億';
        }
        else
            return value.toFixed(0);
    };
    //全部 期現不一樣可以顯示 股票&期貨
    const display = displayType === 'all' && indexSymbol !== futuresSymbol;
    const display2 = displayType === 'futures' && indexSymbol === futuresSymbol;
    return (<styleds.container selected={selected}>
      <styleds.infoContainer>
        {displayType !== 'futures' && (<styleds.quote.container selected={indexSelected} onClick={() => {
                store.charting.changeSymbol(indexSymbol);
                store.charting.setStrategy(props.strategyIndexConfig);
                store.charting.updateFromStrategyState();
            }}>
            <styleds.quote.codeName>
              {indexSymbol} {dictionary[indexSymbol]}
            </styleds.quote.codeName>

            <styleds.quote.quoteValue quote={0}>
              <styleds.quote.priceValue limit={iLimit} quote={indexChanges.closeChange}>
                {autoDecimal(futuresClose)}
              </styleds.quote.priceValue>
            </styleds.quote.quoteValue>

            <styleds.quote.quoteValue quote={indexChanges.closeChange}>
              {indexChangeSymbol}
              {autoDecimal(indexChanges.closeChange)}
            </styleds.quote.quoteValue>

            <styleds.quote.quoteValue quote={indexChanges.closeChange}>
              {indexChangeSymbol}
              {indexChanges.closeChangePercent.toFixed(2)}%
            </styleds.quote.quoteValue>

            <styleds.quote.quoteVolume>{volumeTo8e(indexVolume)}</styleds.quote.quoteVolume>
          </styleds.quote.container>)}

        {(display || display2) && (<styleds.quote.container selected={futuresSelected} onClick={() => {
                store.charting.changeSymbol(futuresSymbol);
                store.charting.setStrategy(props.strategyFuturesConfig);
                store.charting.updateFromStrategyState();
            }}>
            <styleds.quote.codeName>
              {futuresSymbol} {dictionary[futuresSymbol]}
            </styleds.quote.codeName>

            <styleds.quote.quoteValue quote={0}>
              <styleds.quote.priceValue limit={fLimit} quote={futuresChanges.closeChange}>
                {autoDecimal(futuresClose)}
              </styleds.quote.priceValue>
            </styleds.quote.quoteValue>

            <styleds.quote.quoteValue quote={futuresChanges.closeChange}>
              {futuresChangeSymbol}
              {autoDecimal(futuresChanges.closeChange)}
            </styleds.quote.quoteValue>

            <styleds.quote.quoteValue quote={futuresChanges.closeChange}>
              {futuresChangeSymbol}
              {futuresChanges.closeChangePercent.toFixed(2)}%
            </styleds.quote.quoteValue>

            <styleds.quote.quoteVolume>{volumeTo8e(futuresVolume)}</styleds.quote.quoteVolume>
          </styleds.quote.container>)}
      </styleds.infoContainer>
    </styleds.container>);
});
const StockIndexFuturesSymbolHeader = memo(function StockIndexFuturesSymbolHeader(props) {
    const sortGroup = props.sortGroup ?? 1;
    /** 排序元件的組別 */
    const sortStore = () => {
        if (sortGroup === 2) {
            return useSortSignalrDataStore2;
        }
        else
            return useSortSignalrDataStore;
    };
    const sortState = useSnapshot(sortStore());
    const toggleOrderKey = sortState.orderKey === 'desc' ? 'asc' : 'desc';
    const handleColumnSelected = useCallback((sortKey) => {
        sortStore().sortKey = sortKey;
        sortStore().orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<styleds.header>
        <span onClick={() => handleColumnSelected('symbol')}>
          商品
          <SortArrow sortKey='symbol' sotre={sortStore()}/>
        </span>

        <span onClick={() => handleColumnSelected('close')}>
          成交
          <SortArrow sortKey='close' sotre={sortStore()}/>
        </span>

        <span onClick={() => handleColumnSelected('change')}>
          漲跌
          <SortArrow sortKey='change' sotre={sortStore()}/>
        </span>

        <span onClick={() => handleColumnSelected('changePrecent')}>
          幅度
          <SortArrow sortKey='changePrecent' sotre={sortStore()}/>
        </span>

        <span onClick={() => handleColumnSelected('volume')}>
          總量
          <SortArrow sortKey='volume' sotre={sortStore()}/>
        </span>
        <span onClick={() => ''} css={css `
            white-space: nowrap;
            text-overflow: ellipsis;
          `}>
          {props.children}
        </span>
      </styleds.header>);
});
export default {
    body: StockIndexFuturesSymbolBody,
    header: StockIndexFuturesSymbolHeader,
};
const styleds = {
    header: styled.div `
    width: 100%;
    height: 36px;
    display: grid;
    grid-template-columns: 36% 14% 14% 14% 14% 8%;
    font-size: 13px;
    font-weight: 400;
    background-color: #363636;
    border-radius: 4px;
    & > * {
      ${fill_horizontal_all_center};
      cursor: pointer;
    }
  `,
    container: styled.div `
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 100% 0%;
    font-size: 12.6px;
    font-weight: 400;
    border: 1px solid ${props => (!props.selected ? '#454545' : '#f1f1f1')};
    color: ${props => (!props.selected ? '#bbbbbb' : '#f1f1f1')};
    border-radius: 4px;
    flex-shrink: 0;
    padding: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
    infoContainer: styled.div `
    ${fill_vertical_all_center};
  `,
    quote: {
        container: styled.div `
      ${fill_horizontal_cross_center};
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 36% 16% 16% 16% 16%;
      background-color: ${props => (props.selected ? '#292929;' : 'transparent')};
      border-radius: 4px;
      cursor: pointer;
      padding: 0 4px;
      font-weight: 500;
      letter-spacing: -0.2px;
      &:hover {
        background-color: #343434;
        transition: 0.3s;
      }
    `,
        codeName: styled.div `
      ${fill_horizontal_cross_center};
      font-weight: 600;
      letter-spacing: -0.6px;
    `,
        quoteValue: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
      color: ${props => (props.quote > 0 ? '#ff0000' : props.quote < 0 ? '#57ef38' : '#ffffff')};
    `,
        priceValue: styled.div `
      padding: 2px;
      border-radius: 2px;
      background-color: ${props => props.limit === true && props.quote > 0
            ? '#ff0000'
            : props.limit === true && props.quote < 0
                ? '#57cf38'
                : 'transparent'};
      color: ${props => props.limit !== true && props.quote > 0
            ? '#ff0000'
            : props.limit !== true && props.quote < 0
                ? '#57ef38'
                : '#ffffff'};
    `,
        quoteVolume: styled.div `
      ${fill_horizontal_cross_center};
      justify-content: end;
      color: #ffee00;
    `,
    },
    chartContainer: styled.div `
    ${fill_vertical_all_center};
    padding: 8px 2px;
  `,
};
