import { memo } from 'react-tracked';
import { proxy, useSnapshot } from 'valtio';
import { useDoubleVolumeMA, useTdccStock, } from '~/modules/screener/containers/useStockScreenerResource';
import { staticStore } from '../heineken_template/_private/staticStore';
import styled from '@emotion/styled';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useEffect } from 'react';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { css } from '@emotion/react';
import { Slider } from '@mantine/core';
import { daddy960_stocknerve_strategyGroup } from './daddy960_stocknerve_initStrategies';
import StockIndexFuturesSymbol from './component/StockIndexFuturesSymbol';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
export const stocknerve_store = proxy({
    indexType: true,
    indicatorsType: 'index',
    stockValue: 2,
    pageState: 'index',
    displayType: 'all',
});
const stockValueMark = [
    { value: 1, label: '5千萬以上' },
    { value: 2, label: '5億以上' },
    { value: 3, label: '20億以上' },
];
export const Daddy960_stocknerve_SidePane3 = memo(function Daddy960_stocknerve_SidePane3() {
    const state = useSnapshot(stocknerve_store);
    const baseDate = useSnapshot(staticStore).tradedDate.intraday;
    /** 雙均線選股資料 */
    const doubleVolumeMa = useDoubleVolumeMA({
        date: baseDate.format('YYYY-MM-DD'),
        ma1: 3,
        ma2: 20,
        volume_gte: 1000,
        amount_gte: state.stockValue === 1 ? 5e7 : state.stockValue === 2 ? 5e8 : 2e9,
    }).data;
    /** 與上週相比的大戶增減 */
    const tdccSymbol = useTdccStock({
        date: baseDate.format('YYYY-MM-DD'),
        bs: 'b',
        weeks_ago: 1,
    }).data;
    const stockFutures = useSnapshot(staticStore).symbol.stockFuturesMapper;
    const stockFuturesSymbol = Object.keys(stockFutures ?? []);
    const doubleVolumeMaSymbol = doubleVolumeMa?.map(s => s);
    const screenerSymbol = doubleVolumeMaSymbol?.filter(item1 => tdccSymbol?.some(item2 => item2 === item1));
    const resultSymbol = screenerSymbol?.filter(item1 => stockFuturesSymbol?.some(item2 => item2 === item1));
    const futuresDisctionary = useSnapshot(staticStore.symbol.stockFuturesMapper);
    //現貨報價
    const indexSymbols = resultSymbol ?? [''];
    //期貨商品
    const futuresSymbols = resultSymbol?.map(s => futuresDisctionary[s] + '-1') ?? [''];
    //拿取報價 分別是現貨與期貨
    useEffect(() => {
        signalrStore2.addQuote(indexSymbols);
        return () => {
            signalrStore2.removeQuote(indexSymbols);
        };
    }, [JSON.stringify(indexSymbols)]);
    useEffect(() => {
        signalrStore2.addQuote(futuresSymbols);
        return () => {
            signalrStore2.removeQuote(futuresSymbols);
        };
    }, [JSON.stringify(futuresSymbols)]);
    const indexValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(indexSymbols, 2));
    const futuresValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(futuresSymbols, 2));
    const config = state.indexType === true
        ? daddy960_stocknerve_strategyGroup.main[1]
        : daddy960_stocknerve_strategyGroup.main[0];
    return (<styleds.container>
        <styleds.dashboardTitle>
          <styleds.resultTitle>成交值篩選</styleds.resultTitle>
        </styleds.dashboardTitle>
        <div css={css `
            width: 100%;
            height: 56px;
          `}>
          <styleds.dashboard>
            <styleds.card css={css `
                padding: 0px 32px;
              `}>
              <Slider css={css `
                  width: 100%;
                  margin-bottom: 16px;
                `} color='indigo' radius='xs' min={1} max={3} step={1} marks={stockValueMark} label={null} labelTransition='fade' size={6} value={state.stockValue} onChange={value => {
            stocknerve_store.stockValue = value;
        }}/>
            </styleds.card>
          </styleds.dashboard>
        </div>

        <styleds.resultTitle>選股結果：{resultSymbol?.length}檔</styleds.resultTitle>
        <StockIndexFuturesSymbol.header sortGroup={2}/>
        <styleds.listContent>
          {!config && <div>找不到 config</div>}
          {!!config && (<SymbolList indexSymbol={indexValue} futuresSymbol={futuresValue} strategyConfig={config}/>)}
        </styleds.listContent>
      </styleds.container>);
});
const SymbolList = memo(function SymbolList(props) {
    //
    const state = useSnapshot(stocknerve_store);
    const stockFutures = useSnapshot(staticStore).symbol.stockFuturesMapper;
    const main = state.displayType !== 'futures' ? props.indexSymbol : props.futuresSymbol;
    const sub = state.displayType !== 'futures' ? props.futuresSymbol : props.indexSymbol;
    const newData = main.map(s => {
        const matchingData = sub.find(item => item.symbol === stockFutures[s.symbol] + '-1');
        if (matchingData) {
            return {
                indexSignalr: s,
                futuresSignalr: matchingData,
            };
        }
        else {
            return {
                indexSignalr: s,
                futuresSignalr: s,
            };
        }
    });
    return (<div css={css `
        ${fill_vertical_cross_center};
        gap: 4px;
        padding: 4px;
      `}>
      {newData.map(symbol => {
            return (<StockIndexFuturesSymbol.body key={symbol.indexSignalr.symbol} indexSymbol={symbol.indexSignalr} futuresSymbol={symbol.futuresSignalr} strategyIndexConfig={daddy960_stocknerve_strategyGroup.main[1]} strategyFuturesConfig={daddy960_stocknerve_strategyGroup.main[0]}/>);
        })}
    </div>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #191919;
    user-select: none;
    padding: 4px;
    gap: 4px;
  `,
    listContent: styled.div `
    ${fill_vertical_all_center};
    ${scrollbar2Css};
    height: calc(100% - 176px);
    background-color: #191919;
    gap: 4px;
  `,
    resultTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    background-color: #252525;
    border-radius: 5px;
    gap: 4px;
    font-size: 14px;
  `,
    dashboardTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    grid-gap: 4px;
  `,
    dashboard: styled.div `
    ${fill_horizontal_all_center};
    height: 56px;
    grid-gap: 4px;
  `,
    card: styled.div `
    ${fill_vertical_all_center};
    background-color: #1f1f1f;
    border-radius: 4px;
    font-size: 14px;
  `,
};
