import { css } from '@emotion/react';
import { store } from '~/pages/heineken_template/_private/store';
import WatchSymbolToggleButtonSingle from '../heineken_template/_col/col_WatchSymbolToggleSingleButton';
export const daddy960_stocknerve_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.showPriceLine': true,
        'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
        'mainSeriesProperties.candleStyle.downColor': '#00ff00',
        'mainSeriesProperties.candleStyle.upColor': '#ff0000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.wickUpColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'paneProperties.vertGridProperties.color': '#363636',
        'paneProperties.horzGridProperties.color': '#363636',
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#16161e',
        'paneProperties.legendProperties.showSeriesOHLC': true,
        'timeScale.rightOffset': 5,
        'scalesProperties.fontSize': 13,
        'scalesProperties.textColor': '#f8f9f9',
        'scalesProperties.showSeriesLastValue': true,
        'scalesProperties.showStudyLastValue': true,
    };
    templateProps.globalCssset = css `
    #__body {
      ${WatchSymbolToggleButtonSingle.classes.Root} {
        height: 32px;
      }
      ${(WatchSymbolToggleButtonSingle.buttonClasses.notJoined = '#425ad3')}
    }
  `;
};
